<template>
  <div class="row" v-if="show">
    <div class="col-12">
      <div v-for="update in changelog.updates" class="row">
        <h3>v{{ update.version }} - {{ update.date }}</h3>
        <ul class="tx-white-8 tx-medium">
          <li v-for="change in update.changes">
            <span :class="'badge ' + change.class">{{ change.type }}</span>
            {{ change.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/auth-header";
export default {
  name: "Changelog",
  data() {
    return {
      changelog: [],
      show: false,
    };
  },
  mounted() {
    this.loadVersions();
  },
  methods: {
    loadVersions() {
      axios
        .get(`https://api.varsanpr.com/api/misc/changelog`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.changelog = response.data;
          this.show = true;
        })
        .catch((error) => {
          this.$error("Failed to load changelog");
        });
    },
  },
};
</script>
